// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as QrcodeReact from "qrcode.react";
import * as IssuedCertificate from "../shared/types/IssuedCertificate.bs.js";

function str(prim) {
  return prim;
}

function paddingPercentage(issuedCertificate) {
  return String(IssuedCertificate.margin(issuedCertificate)) + "%";
}

function qrCodeStyle(issuedCertificate) {
  return {
          padding: paddingPercentage(issuedCertificate)
        };
}

function qrPositionClasses(issuedCertificate) {
  var match = IssuedCertificate.qrCorner(issuedCertificate);
  if (match === "TopRight") {
    return "top-0 right-0";
  } else if (match === "BottomLeft") {
    return "bottom-0 left-0";
  } else if (match === "BottomRight") {
    return "bottom-0 right-0";
  } else if (match === "TopLeft") {
    return "top-0 left-0";
  } else {
    return "hidden";
  }
}

function qrContainerStyle(issuedCertificate) {
  var widthPercentage = IssuedCertificate.qrScale(issuedCertificate) / 100.0 * 10.0;
  return {
          width: widthPercentage.toString() + "%"
        };
}

function certificateUrl(issuedCertificate) {
  var prefix = window.location.origin;
  var suffix = "/c/" + IssuedCertificate.serialNumber(issuedCertificate);
  return prefix + suffix;
}

function qrCode(issuedCertificate, verifyImageUrl) {
  var match = IssuedCertificate.qrCorner(issuedCertificate);
  if (match === "Hidden") {
    return null;
  } else {
    return React.createElement("div", {
                className: "absolute " + qrPositionClasses(issuedCertificate),
                style: qrContainerStyle(issuedCertificate)
              }, React.createElement(QrcodeReact.QRCodeCanvas, {
                    value: certificateUrl(issuedCertificate),
                    size: 256,
                    bgColor: "transparent",
                    level: "Q",
                    imageSettings: {
                      src: verifyImageUrl,
                      height: 29,
                      width: 133,
                      excavate: true
                    },
                    style: {
                      height: "100%",
                      width: "100%"
                    },
                    className: "w-full h-full"
                  }));
  }
}

function IssuedCertificate__Root(Props) {
  var issuedCertificate = Props.issuedCertificate;
  var verifyImageUrl = Props.verifyImageUrl;
  return React.createElement("div", {
              className: "relative w-full h-full"
            }, React.createElement("img", {
                  className: "w-full object-contain",
                  src: IssuedCertificate.imageUrl(issuedCertificate)
                }), React.createElement("div", {
                  className: "absolute top-0 left-0 w-full h-full"
                }, React.createElement("div", {
                      className: "relative w-full h-full flex justify-center"
                    }, React.createElement("svg", {
                          height: "100%",
                          width: "100%",
                          preserveAspectRatio: "xMinYMin meet",
                          viewBox: "0 0 500 300"
                        }, React.createElement("foreignObject", {
                              height: "100%",
                              width: "100%",
                              xmlns: "http://www.w3.org/1999/xhtml"
                            }, React.createElement("div", {
                                  className: "w-full flex justify-center py-10 px-4"
                                }, React.createElement("div", {
                                      className: "flex flex-col gap-1 text-center text-black-100 select-none"
                                    }, React.createElement("div", {
                                          className: "text-4xl my-3"
                                        }, "CERTIFICADO"), React.createElement("div", {
                                          className: "text-base"
                                        }, "Certificamos que, para os devidos fins, o(a) aluno(a)"), React.createElement("div", {
                                          className: "text-lg italic"
                                        }, issuedCertificate.issuedTo), React.createElement("div", {
                                          className: "px-6 text-base"
                                        }, "concluiu o curso \"" + issuedCertificate.courseName + "\" na plataforma do JeraPlay"))))), qrCode(issuedCertificate, verifyImageUrl))));
}

var make = IssuedCertificate__Root;

export {
  str ,
  paddingPercentage ,
  qrCodeStyle ,
  qrPositionClasses ,
  qrContainerStyle ,
  certificateUrl ,
  qrCode ,
  make ,
  
}
/* react Not a pure module */
